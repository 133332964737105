import revive_payload_client_opVnqA28fG from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.45_sass@1.62.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ATX5y2fRyz from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.45_sass@1.62.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8lQYyPlH4I from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.45_sass@1.62.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RDemX9xP2B from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.38_nuxt@3.7.4_postcss@8.4._v23eeu63vqrlywci6g5iai7jbm/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_nmo1AbzBZt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.45_sass@1.62.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_QHVxDV8fD7 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.45_sass@1.62.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_EYwWJLw9cR from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.4.38/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.mjs";
import plugin_nhkhge80ze from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.4.38/node_modules/@nuxtjs/prismic/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/opt/build/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_aZYugFxPdo from "/opt/build/repo/node_modules/.pnpm/nuxt-primevue@0.3.1_vue@3.4.38/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_6X6lkeoo35 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.7.4_@types+node@18.19.45_sass@1.62.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_client_mtqVw3YRXk from "/opt/build/repo/plugins/analytics.client.ts";
import emitter_eaMgM0oZTb from "/opt/build/repo/plugins/emitter.ts";
import scroll_client_HJUo2UKx9n from "/opt/build/repo/plugins/scroll.client.ts";
export default [
  revive_payload_client_opVnqA28fG,
  unhead_ATX5y2fRyz,
  router_8lQYyPlH4I,
  _0_siteConfig_RDemX9xP2B,
  payload_client_nmo1AbzBZt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QHVxDV8fD7,
  plugin_client_EYwWJLw9cR,
  plugin_nhkhge80ze,
  primevue_plugin_egKpok8Auk,
  plugin_client_aZYugFxPdo,
  chunk_reload_client_6X6lkeoo35,
  analytics_client_mtqVw3YRXk,
  emitter_eaMgM0oZTb,
  scroll_client_HJUo2UKx9n
]